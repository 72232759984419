import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

import Layout from "../../components/layout";
import HeroWithImage from "../../components/heroWithImage/index";
import GoldBox from "../../components/goldbox";
import Documents from "../../containers/corporate/documents";
import Seo from "../../components/seo";

import heroDesktop from "../../assets/heroDesktop/CorporateGovernance.png";
import heroMobile from "../../assets/heroMobile/CorporateGovernance.png";

const Coporate = ({ data }) => {
 const intl = useIntl();
  const { title, content } = data.commitment.nodes[0];
  return (
    <Layout inverted>
      <Seo title="CORPORATE" />
      <HeroWithImage
        title={intl.locale === "fr" ? "GOUVERNANCE D'ENTREPRISE" : "CORPORATE GOVERNANCE"}
        heroMobile={heroMobile}
        heroDesktop={heroDesktop}
      />
      <GoldBox title={title} main={content} white />
      <Documents
        data={data.docs.nodes[0].childrenContentfulCorporateGovernanceReferenceDocumentsDocumentsJsonNode[0]}
        title={data.docs.nodes[0].title}
      />
    </Layout>
  );
};

export const query = graphql`
  query CorporateGovernance($locale: String) {
    commitment: allContentfulCorporateGovernanceOurCommitment(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        title
        content {
          raw
        }
      }
    }
    docs: allContentfulCorporateGovernanceReferenceDocuments(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        title
        childrenContentfulCorporateGovernanceReferenceDocumentsDocumentsJsonNode {
          items {
            title
            items {
              download_link
              title
              type
            }
          }
        }
      }
    }
  }
`;

export default Coporate;
